import React from 'react';
import {
  Icon,
  Text,
  Box,
  ButtonAnimated,
  ShowBreakpoint,
  HideBreakpoint,
} from '@strikelabs/luna';
import { Link } from 'gatsby';

import SEO from 'components/SEO';
import Layout from 'components/Layout';
import Board from 'images/404_board.png';

import {
  Container,
  HeaderContainer,
  ContentWrapper,
  ImageContainer,
  Image,
  InnerContainer,
  CopyContainer,
  Copy,
} from './style';

const PageNotFound = () => (
  <Layout noHeader>
    <Container>
      <SEO title="404 | Not found" />
      <HeaderContainer>
        <ShowBreakpoint breakpoint="tablet">
          <Link to="/">
            <Icon type="strikeLogo" width="105px" theme="dark" />
          </Link>
        </ShowBreakpoint>
        <HideBreakpoint breakpoint="tablet">
          <Link to="/">
            <Icon type="strikeLogo" width="90px" theme="dark" />
          </Link>
        </HideBreakpoint>
      </HeaderContainer>
      <InnerContainer>
        <ContentWrapper>
          <ImageContainer>
            <Image image={Board} alt="For sale board" />
          </ImageContainer>
          <CopyContainer>
            <Box>
              <Box>
                <Text as="h1" styleType="h2" ext>
                  See what we did there?
                </Text>
              </Box>
              <Box mt={1}>
                <Copy>We can&apos;t find that page, sorry.</Copy>
              </Box>
              <Box mt={3}>
                <ButtonAnimated to="/">Take me to strike.co.uk</ButtonAnimated>
              </Box>
            </Box>
          </CopyContainer>
        </ContentWrapper>
      </InnerContainer>
    </Container>
  </Layout>
);

export default PageNotFound;
