import styled from 'styled-components';
import { rem } from 'polished';

export const Container = styled.div`
  height: calc(100vh - 83px);
  background-color: #dfe4e7;
  margin: 0 auto;
  overflow: hidden;
  font-size: ${({ theme }) => theme.defaults.fontSize};
  font-family: ${({ theme }) => theme.defaults.fontFamily};
`;

export const InnerContainer = styled.div`
  margin: 0 auto;
  max-width: ${rem(2200)};
  height: 100%;
`;

export const HeaderContainer = styled.div`
  max-width: auto;
  padding: 0 ${({ theme }) => `${theme.defaults.gutterSpacing}`};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${rem(83)};

  ${({ theme }) => theme.media.giant} {
    padding: 0 ${rem(64)};
  }
`;

export const Copy = styled.p`
  font-size: ${rem(18)};
  line-height: ${rem(28)};
  font-weight: 500;

  ${({ theme }) => theme.media.desktop} {
    font-size: ${rem(22)};
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 ${({ theme }) => theme.defaults.gutterSpacing};
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.giant} {
    flex-direction: row;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  overflow: hidden;
`;

export const CopyContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  margin: ${({ theme }) => `${theme.spacing.xl} 0`};
  text-align: center;

  ${({ theme }) => theme.media.giant} {
    margin-top: 0;
    text-align: left;
  }
`;

export const Image = styled.div`
  background-image: ${({ image }) => `url(${image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center 100%;
  width: 100%;
  height: 100%;
`;
